import React, { useState, useRef } from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

export const query = graphql`
  query {
    youngTeam: file(relativePath: { eq: "young-team.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allSanityClubevent {
      nodes {
        club {
          title
        }
        slug {
          current
        }
        title
        excerpt
        lastDayDate: endDate
        firstDayDate: startDate
        startDate(formatString: "MMM-DD")
        endDate(formatString: "MMM-DD")
        mainImage {
          asset {
            fluid {
              base64
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default function ({
  data: {
    allSanityClubevent: { nodes: events },
    youngTeam,
  },
}) {
  const [eventFilter, setEventFilter] = useState(0)
  const filters = ["all", "upcoming", "ongoing", "completed"]
  let noEvent = 0
  let eventCount = 0
  const eventsRef = useRef()

  return (
    <Layout>
   <SEO title="Events"/>
      <section className="px-4 py-16 w-full lg:border-r lg:border-b border-gray-600 text-center  md:pb-20 lg:px-32 md:mt-16">
        <div className="section-for-small-devices">
          <h1 className="section-head">
            <span className="text-red-800">Student Club</span> Events
          </h1>
          <p className="mt-4 md:mt-8">
            10+ Student Clubs organize various programs all year round, which
            are among the best things Bloom has to offer.
          </p>
          <Img
            className="mt-4 md:mt-8"
            fluid={youngTeam.childImageSharp.fluid}
            alt="A team of young students grouped together to celebrate for something and are in joyous mood"
            draggable={false}
          />
        </div>
      </section>
      <section
        className="sticky top-0  lg:border-b md:mt-32 lg:-mx-16 no-x-margin bg-gray-300"
        style={{
          zIndex: 10,
        }}
        id="filterEvents"
      >
        <div className="text-xs md:text-sm text-gray-600 uppercase tracking-wide font-semibold">
          Filter Events
        </div>
        <div className="w-100 overflow-x-scroll whitespace-no-wrap mt-2">
          {filters.map((filter, index) => (
            <button
              onClick={event => {
                setEventFilter(index)
                // scroll to top of the list
                eventsRef.current.scrollIntoView()
                window.scrollBy(0, -event.target.offsetHeight)
              }}
              className={`pr-4 button-outline inline-block text-gray-700 cursor-pointer uppercase hover:text-black font-medium ${
                index === eventFilter ? "underline text-gray-900" : ""
              }`}
              key={index}
            >
              {filter}
            </button>
          ))}
        </div>
      </section>
      <section className="bg-white text-white px-auto mt-0" ref={eventsRef}>
        <ul className="flex flex-wrap">
          {events.map((event, index) => {
            let eventStatus = ""
            let eventBgColor = ""
            if (new Date() > new Date(event.lastDayDate)) {
              eventStatus = "completed"
              eventBgColor = "bg-red-700"
            } else if (new Date() < new Date(event.firstDayDate)) {
              eventStatus = "upcoming"
              eventBgColor = "bg-blue-600"
            } else {
              eventStatus = "ongoing"
              eventBgColor = "bg-green-600"
            }

            //selectively display depending on the selected filter
            // eventFilter === 0 means filter is set to all
            if (eventStatus === filters[eventFilter] || eventFilter === 0) {
              eventCount++
              const order = index % 2
              return (
                <Link
                  to={`/events/${event.slug.current}`}
                  key={index}
                  className="w-full"
                >
                  <li
                    className={`${
                      order === 0
                        ? "lg:border-r lg:border-l-0"
                        : "lg:border-l lg:border-r-0 lg:flex-row-reverse"
                    } border-gray-600 lg:border-b lg:flex items-center lg:px-8 lg:py-16 md:mt-20 mt-16 md:px-16 sm:px-12 px-8 md:py-16 sm:py-12 py-8 lg:bg-transparent bg-gray-200 lg:hover:bg-gray-200 max-w-md sm:max-w-lg md:max-w-xl mx-auto lg:max-w-full lg:border-t-0`}
                    // className={`${
                    //   order === 0
                    //     ? "lg:border-r"
                    //     : "lg:border-l lg:flex-row-reverse"
                    // } border-gray-600 border-b lg:flex items-center lg:px-8 lg:py-16 lg:mb-32 hover:bg-gray-200`}
                  >
                    <div
                      className={`${
                        order === 0 ? "lg:pr-8" : "lg:pl-8"
                      } lg:w-1/2`}
                    >
                      <h2 className="text-2xl font-medium leading-tight lg:text-left text-center">
                        <span className="border-b-2 border-black inline-block">
                          {event.title}
                        </span>
                      </h2>
                      <Img
                        className="lg:hidden mt-4"
                        fluid={event.mainImage.asset.fluid}
                        alt={event.title}
                        draggable={false}
                      />
                      <p className="lg:text-gray-800 mt-6">{event.excerpt}</p>
                      <div className="lg:mt-12 mt-4">
                        <span className="pr-2 text-base">Organizer:</span>{" "}
                        <span className="font-medium">{event.club.title}</span>
                      </div>
                      <div className="lg:mt-4 mt-2">
                        <div className="text-base">
                          <span
                            className={`${eventBgColor} text-white px-2 mr-2 rounded-full uppercase text-sm font-medium`}
                          >
                            {eventStatus}
                          </span>
                          <br />
                          {event.startDate === event.endDate
                            ? `${event.startDate}`
                            : `${event.startDate} -  ${event.endDate}`}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        order === 0 ? "lg:pl-8" : "lg:pr-8"
                      } lg:w-1/2`}
                    >
                      <Img
                        fluid={event.mainImage.asset.fluid}
                        className="hidden lg:block"
                        alt={event.title}
                        draggable={false}
                      />
                    </div>
                  </li>
                </Link>
              )
            } else {
              noEvent++
              eventCount++
              return ""
            }
          })}
        </ul>
        {eventCount === noEvent ? (
          <div className="text-black">
            <div className="lowercase text-6xl text-gray-800">
              Oops <span className="font-form">:(</span>
            </div>
            <div>
              Sorry there are no{" "}
              {filters[eventFilter] !== "all" ? filters[eventFilter] : ""}{" "}
              events. Please check back soon!
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </Layout>
  )
}